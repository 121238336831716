//
// authentication.scss
//

.auth-body-bg {
    background-color: $card-bg;
}

.authentication-bg {
    background-color: #0c9440;
    background-image: url("../../../images/authentication-bg.png");
    height: 100vh;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    // .bg-overlay {
    //     background-color: #0a0a0a;
    // }

    @media (max-width: 991px) {
        display: none;
    }
}

.entete-bg {
    //background-color: #0c9440;
    background-image: url("../../../images/entete.png");
    height: 23vh;
    background-size: cover;
    //background-position: left;
    background-repeat: no-repeat;
    // .bg-overlay {
    //     background-color: #0a0a0a;
    // }

    // @media (max-width: 991px) {
    //     display: none;
    // }
}

.entete-bg-small {
    
    //background-color: #0c9440;
    background-image: url("../../../images/entete2.png");
    height: 18vh;
    background-size: cover;
    //background-position: left;
    background-repeat: no-repeat;
    background-position: center; /* Focus on the center of the image */

}

.notice-bg {
    background-color: #efbaba;
    height: 5vh;
    background-size: cover;
    color:red;
    padding: 8px 20px;
    margin-top: 10px;
    text-align: center;
    border: 1px solid #ff4800;
    border-radius: 20px;
    font-weight: bold;
    font-size: 18px;
}

.authentication-page-content {
    height: 100vh;
    display: flex;
    background-color: #fa8639;
}

.auth-form-group-custom {
    position: relative;
    .form-control {
        height: 60px;
        padding-top: 28px;
        padding-left: 60px;
    }

    label {
        position: absolute;
        top: 7px;
        left: 60px;
    }

    .auti-custom-input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 19px;
        font-size: 24px;
        color: $primary;
    }
}

.auth-logo {
    &.logo-light {
        display: $display-none;
    }
    &.logo-dark {
        display: $display-block;
    }
}

[data-bs-theme="dark"] {
    .authentication-logo {
        .logo-light {
            display: block;
        }
        .logo-dark {
            display: none;
        }
    }
}
