.step {
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 20px;
  font-weight: bold;
  color: #007bff;
  background-color: #f8f9fa;
  transition: all 0.3s ease-in-out;
}

.step.active {
  background-color: #007bff;
  color: white;
}

.arrow {
  margin: 0 10px;
  font-weight: bold;
  color: #6c757d;
}

.password-hint {
  transition: opacity 0.3s ease;
  opacity: 0.9; /* Slightly transparent for a softer appearance */
  width: 400px; /* Adjust width as needed */
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.pagination-icon-button {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #d1d5db;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.pagination-icon-button:disabled {
  color: #9ca3af;
  border-color: #e5e7eb;
  cursor: not-allowed;
}

.pagination-icon-button:hover:not(:disabled) {
  background-color: #4f46e5;
  color: #ffffff;
}

.pagination-range {
  margin: 0 10px;
  font-weight: 500;
}

.payment-block {
  position: relative;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
}

.status-frais-stripe {
  position: absolute;
  top: 90px; /* Adjust as needed */
  right: -30px; /* Adjust as needed */
  padding: 5px 20px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  transform: rotate(50deg);
  border-top-right-radius: 8px;
  background-color: #5cb85c; /* Default color */
}
.status-certificat-stripe {
  position: absolute;
  top: 70px; /* Adjust as needed */
  right: -45px; /* Adjust as needed */
  padding: 5px 20px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  transform: rotate(50deg);
  border-top-right-radius: 8px;
  background-color: #5cb85c; /* Default color */
}

.status-frais-pending {
  background-color: #f0ad4e; /* Orange for PENDING */
}

.status-frais-failed {
  background-color: #d9534f; /* Red for FAILED */
}

.status-frais-successful {
  background-color: #5cb85c; /* Green for SUCCESSFUL */
}

.status-certificat-pending {
  background-color: #f0ad4e; /* Orange for PENDING */
}

.status-certificat-failed {
  background-color: #d9534f; /* Red for FAILED */
}

.status-certificat-successful {
  background-color: #5cb85c; /* Green for SUCCESSFUL */
}

.filter-input {
  width: 150px;
}

.filter-button {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #d1d5db;
  padding: 3px 8px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}
.filter-button:hover {
  background-color: #4f46e5;
  color: #ffffff;
}
.excel-button {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #d1d5db;
  padding: 3px 8px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 2px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.excel-button:hover {
  background-color: #28a745;
  color: #ffffff;
}

.total-row {
  background-color: #c2ffc7;
  font-weight: bolder;
}
.custom-alert {
  display: inline-block;
  padding: 10px 20px; /* Uniform padding for better visual consistency */
  border-radius: 4px; /* Optional: Add rounded corners for a polished look */
  font-size: 14px; /* Adjust font size for readability */
}
